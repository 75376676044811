import { useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
    Accordion,
    Container,
    Input,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import SEO from '../components/SEO';
import { SanityFaq, SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import PageHeader from '../components/PageHeader';

interface FAQPageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function FAQPage({ data }: FAQPageProps) {
    const { translate, format } = useLocale();
    const [searchTerm, setSearchTerm] = useState('');

    const questions = useMemo(() => {
        if (!data?.page?.content) return [];
        const allFAQs = data.page.content.filter(item => item?._type === 'faq');
        return allFAQs.filter(item => {
            if (!item) return false;
            const question = translate((item as SanityFaq)?.title || {});
            return question.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [searchTerm, data.page.content, translate]);

    return (
        <div>
            <SEO
                title={
                    data.page.title
                        ? translate(data.page.title)
                        : 'Preguntas Frecuentes'
                }
            />
            {data.page.title && (
                <PageHeader title={data.page.title}>
                    <Container>
                        <InputGroup size="lg" mt={16}>
                            <InputLeftElement pointerEvents="none">
                                <FiSearch color="gray.300" />
                            </InputLeftElement>
                            <Input
                                placeholder={format('search')}
                                focusBorderColor="primary.500"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                        </InputGroup>
                    </Container>
                </PageHeader>
            )}
            <Container maxW="container.lg" pb={12}>
                <Accordion allowMultiple>
                    <Content data={questions} spacing={0} />
                </Accordion>
            </Container>
        </div>
    );
}

export const query = graphql`
    query FAQPageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "preguntas-frecuentes" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
